<template>
  <div class="row">
    <div class="flex md12">
      <va-input
        class="mb-4"
        v-model="form.title"
        label="Title"
        placeholder="Title"/>
      <va-input
        class="mb-4"
        v-model="form.adventureType"
        label="Type d'aventure (ex: Randonnée, Culture, etc)"
        placeholder="Type d'aventure"/>
      <h6 class="display-6 mb-3">Description du Voyage</h6>
      <va-input
        class="mb-4"
        v-model="form.description"
        type="textarea"
        label="Description"
        :min-rows="5"
        placeholder="Décrivez votre voyage organisé. Vendez du rêve !"/>
      <h6 class="display-6 mb-3">Le Voyage Inclus</h6>
      <va-input
        class="mb-4"
        v-model="form.inclusionText"
        type="textarea"
        label="Inclus"
        :min-rows="5"
        placeholder="Listez ici tout ce que votre voyage inclus (sur plusieurs lignes)
eg:
Petit Déjeuner
Animation
Hébergement"/>
      <h6 class="display-6 mb-3">Le Voyage N'Inclus pas</h6>
      <va-input
        class="mb-4"
        v-model="form.exclusionText"
        type="textarea"
        label="Non Inclus"
        :min-rows="5"
        placeholder="Listez ici tout ce que votre voyage n'inclus pas (sur plusieurs lignes)
eg:
Billet d'avion
Visa
Déjeuners et Diners
"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProgramUpdateGeneral",
  props: {
    modelValue: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      form: this.modelValue
    }
  },
}
</script>

<style scoped>

</style>
