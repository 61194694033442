<template>
  <div class="row">
    <div class="flex md12">
      <va-card>
        <va-card-content>
          <div class="row">
            <div class="flex md4">
              <va-select
                class="mb-4"
                label="Status"
                v-model="statusForm.status"
                :options="statusOptions"
                :track-by="(option) => option.value"
                text-by="label"
                value-by="value"
              />
            </div>
            <div class="flex md2">
              <va-button @click="updateStatus" :loading="loadingStatus">
                Update
              </va-button>
            </div>
            <div class="flex md6">
              Source: <a :href="source">{{ source }}</a>
            </div>
          </div>
        </va-card-content>
      </va-card>
      <va-card>
        <va-card-content>
          <va-tabs v-model="value" grow>
            <template #tabs>
              <va-tab v-for="tab in tabs" :key="tab.title" :name="tab.title">
                <va-icon :name="tab.icon" size="small" class="mr-2" />
                {{tab.title}}
              </va-tab>
            </template>
            <div class="row row-equal mt-3">
              <div class="flex md12">
                <div v-if="currentTab.title === 'General'">
                  <program-update-general v-model="formGeneral" />
                  <div class="row">
                    <div class="flex">
                      <va-button @click="updateBasics" size="large" :loading="loadingBasics">
                        Update
                      </va-button>
                    </div>
                  </div>
                </div>
                <div v-if="currentTab.title === 'Days'">
                  <ProgramCreateDaily v-model="formDaily" :country-options="countryOptions"/>
                  <div class="row">
                    <div class="flex">
                      <va-button @click="updateDaily" size="large" :loading="loadingDaily">
                        Update
                      </va-button>
                    </div>
                  </div>
                </div>
                <div v-else-if="currentTab.title === 'Dates'">
                  <program-create-dates v-model="formDates"/>
                  <div class="row">
                    <div class="flex">
                      <va-button @click="updateDates" size="large" :loading="loadingDates">
                        Update
                      </va-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </va-tabs>
        </va-card-content>
      </va-card>
    </div>
  </div>
</template>

<script>
import ProgramUpdateGeneral from './components/ProgramUpdateGeneral.vue'
import ProgramCreateDates from './ProgramCreateDates.vue'
import ProgramCreateDaily from "./ProgramCreateDaily"
import dayjs from "dayjs";


const TABS = [
  { icon: 'feed', title: 'General', content: 'Feed content' },
  { icon: 'calendar', title: 'Days', content: 'Days content' },
  { icon: 'calendar', title: 'Dates', content: 'Profile content' }
]
export default {
  name: "ProgramUpdate",
  components: {
    ProgramUpdateGeneral,
    ProgramCreateDates,
    ProgramCreateDaily
  },
  data () {
    return {
      tabs: TABS,
      value: TABS[0].title,
      tour: null,
      source: null,
      loadingDates: false,
      loadingBasics: false,
      loadingDaily: false,
      countryOptions: [],
      loadingStatus: false,
      statusOptions: [
        { label: 'Draft', value: 'draft' },
        { label: 'Active', value: 'active' },
        { label: 'Pending', value: 'Pending' },
      ],
      statusForm: {
        status: 'draft',
      },
      formDaily: {
        days: [
          { id: '1', title: '', description: '', cities: [{id: 0, time: null, city: '', location: '', type: 'departure'}]},
          { id: '2', title: '', description: '', cities: []},
          { id: '3', title: '', description: '', cities: []}
        ],
      },
      formGeneral: {
        title: null,
        adventureType: null,
        description: null,
        inclusionText: null,
        exclusionText: null,
      },
      formDates: {
        detailedDates: [],
        baseAdultPrice: null,
        baseChildrenPrice: null,
        baseInfantPrice: null,
        maxChildrenAge: 12,
        maxInfantAge: 2,
        supplements: [],
      },
    }
  },
  async created () {
    await this.getTour()
    const countries = await this.axios.get('/api/countries')
    this.countryOptions = countries.data
  },
  methods: {
    formatTrips(trips) {
      return trips.map(t => {
        return {
          id: t.id,
          priceId: t.price.id,
          date: new Date(t.start_time),
          // parse to float if value is Number else return null
          adultPrice: t.price.base_price ? parseFloat(t.price.base_price) : null,
          childPrice: t.price.child_price ? parseFloat(t.price.child_price) : null,
          infantPrice: t.price.infant_price ? parseFloat(t.price.infant_price) : null,
        }
      })
    },
    formatSupplements(supplements) {
      return supplements.map(s => {
        return {
          id: s.id,
          title: s.title,
          description: s.description,
          price: s.price.base_price ? parseFloat(s.price.base_price) : null,
        }
      })
    },
    formatDays(days) {
      days.sort((a, b) => a.day - b.day)
      return days.map(d => {
        return {
          id: d.id,
          title: d.title,
          description: d.description,
          day: d.day,
          cities: d.checkpoints.map(c => {
            let time = null
            if (c.start_time !== null) {
              const now = new Date()
              const [hours, minutes, seconds] = c.start_time.split(':')
              time = new Date(now.getFullYear(), now.getMonth(), now.getDate(), hours, minutes, seconds)
            }
            return {
              id: c.id,
              time: time,
              city: c.city ? {
                id: c.city.id,
                name: c.city.name
              } : null,
              location: c.address,
              type: c.type,
            }
          })
        }
      })
    },
    async getTour () {
      const response = await this.axios.get(`/api/admin/tours/${this.$route.params.id}/edit`)
      this.tours = response.data
      this.formGeneral.title = response.data.title
      this.formGeneral.description = response.data.description
      this.formGeneral.inclusionText = response.data.inclusion_text
      this.formGeneral.exclusionText = response.data.exclusion_text
      this.formGeneral.adventureType = response.data.adventure_style
      this.formDates.detailedDates = this.formatTrips(response.data.trips)
      this.formDates.supplements = this.formatSupplements(response.data.supplements)
      this.formDaily.days = this.formatDays(response.data.days)
      this.source = response.data.source
    },
    deepDiffMapper (obj1, obj2) {
      const diff = []
      for (const val of obj2) {
        const obj1Val = obj1.find(o => o.id === val.id)
        if (obj1Val) {
          if (JSON.stringify(obj1Val) !== JSON.stringify(val)) {
            diff.push({
              type: 'updated',
              data: val
            })
          }
        } else {
          diff.push({ type: 'created', data: val })
        }
      }
      for (const val of obj1) {
        const obj2Val = obj2.find(o => o.id === val.id)
        if (!obj2Val) {
          diff.push({ type: 'deleted', data: val })
        }
      }
      return diff
    },
    async updateDates () {
      this.loadingDates = true
      const diffDates = this.deepDiffMapper(this.formatTrips(this.tours.trips), this.formDates.detailedDates)
      if (diffDates.length === 0) {
        this.loadingDates = false
        this.$vaToast.init('No changes detected')
        return
      }
      const diffDatesCopy = diffDates.map(d => {
        d.data.date = dayjs(d.data.date).format('YYYY-MM-DD')
        return d
      })
      try {
        const response = await this.axios.put(`/api/admin/trips/${this.$route.params.id}`, {
          diffDates: diffDatesCopy
        })
        console.log(response)
        // TODO: validate form
      } catch (e) {
        console.log(e)
      } finally {
        this.loadingDates = false
      }
    },
    async updateBasics () {
      this.loadingBasics = true
      try {
        const response = await this.axios.put(`/api/admin/tours/${this.$route.params.id}`, this.formGeneral)
        console.log(response)
      } catch (e) {
        console.log(e)
      } finally {
        this.loadingBasics = false
      }
    },
    async updateDaily() {
      this.loadingDaily = true
      const formattedDays = this.formatDays(this.tours.days)
      const newDays = this.formDaily.days.map(({cities, ...keepAttrs}) => keepAttrs)
      const originalTourDays = formattedDays.map(({cities, ...keepAttrs}) => keepAttrs)
      const diffDays = this.deepDiffMapper(originalTourDays, newDays)
      try {
        const response = await this.axios.put(`/api/admin/days/${this.$route.params.id}`, {
          diffDays
        })
      } catch (e) {
        console.log(e)
      }
      const citiesToUpdate = []
      for (const day of formattedDays) {
        const newDay = this.formDaily.days.find(d => d.day === day.day)
        if (newDay) {
          const diffCities = this.deepDiffMapper(day['cities'], newDay['cities'])
          if (diffCities.length > 0) {
            const diffCitiesCopy = diffCities.map(d => {
              d.data.day = day.day
              d.data.time = d.data.time ? dayjs(d.data.time).format('HH:mm:ss') : null
              return d
            })
            citiesToUpdate.push(...diffCitiesCopy)
          }
        }
      }
      try {
        const response = await this.axios.put(`/api/admin/checkpoints/${this.$route.params.id}`, {
          citiesToUpdate
        })
        console.log(response)
      } catch (e) {
        console.log(e)
      }
      this.loadingDaily = false
    },
    async updateStatus() {
      this.loadingStatus = true
      try {
        console.log(this.statusForm.status)
        const response = await this.axios.put(`/api/admin/tours/${this.$route.params.id}/status`, {
          status: this.statusForm.status
        })
        console.log(response)
      } catch (e) {
        console.log(e)
      }
      this.loadingStatus = false
    }
  },
  computed: {
    currentTab () {
      return this.tabs.find(({ title }) => title === this.value)
    },
  },
}
</script>

<style scoped>

</style>
